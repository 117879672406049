<template>
  <q-slide-transition>
    <div v-if="isOpen" :class="styleClasses">
      <div
        v-if="isLoading"
        class="text-primary row fit justify-center items-center"
        style="min-height: 200px"
      >
        <q-spinner color="light-blue-9" size="3rem" />
      </div>

      <div v-else>
        <div
          v-if="options.fields.length <= 0"
          class="row fit justify-center items-center"
          style="min-height: 200px"
        >
          <div class="text-subtitle1 text-center">
            {{ options.noFieldsLabel || $t("No fields") }}
          </div>
        </div>

        <form-builder
          v-else
          :schema="schema"
          :activated-fields="options.fields"
          :no-groups="options.style && options.style.noGroups"
          @submit="handleSubmit"
        />

        <div
          v-if="!options.style || !options.style.noButtons"
          class="row justify-end q-pt-sm q-px-xs"
        >
          <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Apply filters')"
            size="sm"
            no-caps
            unelevated
            @click="handleSubmit"
          />

          <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Close')"
            size="sm"
            no-caps
            unelevated
            @click="handleClose"
          />

          <q-btn
            color="warning"
            text-color="white"
            :label="$t('Reset')"
            size="sm"
            no-caps
            unelevated
            @click="handleReset"
          />
        </div>
      </div>
    </div>
  </q-slide-transition>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

import AppOptions from '../../../../config/AppOptions'

/**
 * Create filter model with all possible filters
 *
 * @returns {object}
 */
function createModel () {
  return {
    id: null,
    extId: null,
    article: null,
    sku: null,
    clientExtId: null,
    clientId: null,
    trackingNumber: null,
    shippedByDocument: null,
    createdByDocument: null,
    'created_by_document_id': null,
    recipient: null,
    recipientPhone: null,
    profile: null,
    phone: null,
    deliveryTime: null,
    offerType: null,
    entityClass: null,
    weight: {
      from: null,
      to: null
    },
    items: {
      from: null,
      to: null
    },
    quantityExpected: {
      from: null,
      to: null
    },
    quantityReceived: {
      from: null,
      to: null
    },
    quantityAllocated: {
      from: null,
      to: null
    },
    quantityShipped: {
      from: null,
      to: null
    },
    orderQuantityFBA: {
        from: null,
        to: null
    },
    orderQuantityFBM: {
        from: null,
        to: null
    },
    price: {
      from: null,
      to: null
    },
    retailPrice: {
      from: null,
      to: null
    },
    estimatedCost: {
      from: null,
      to: null
    },
    payment: {
      from: null,
      to: null
    },
    deliveryDate: {
      from: null,
      to: null
    },
    shipmentDate: {
      from: null,
      to: null
    },
    transactionDate: {
      from: null,
      to: null
    },
    pickUpDate: {
      from: null,
      to: null
    },
    sendDate: {
      from: null,
      to: null
    },
    updated: {
      from: null,
      to: null
    },
    created: {
      from: null,
      to: null
    },
    extDate: {
      from: null,
      to: null
    },
    time: {
      from: null,
      to: null
    },
    updateTime: {
      from: null,
      to: null
    },
    balance: {
      from: null,
      to: null
    },
    date: {
      from: null,
      to: null
    },
    sequence: null,
    deliveryService: [],
    rate: null,
    servicePoint: null,
    integration: null,
    warehouse: null,
    paymentState: null,
    sender: null,
    shop: [],
    channel: null,
    task: null,
    source: null,
    state: [],
    type: [],
    isNull: [],
    isNotNull: [],
    country: null,
    owner: null,
    executive: null,
    reportType: null,
    room: null,
    row: null,
    rack: null,
    shelf: null,
    root: null,
    volume: null,
    name: null,
    username: null,
    email: null,
    domain: [],
    reserve: null,
    barcode: null,
    locationAddress: null,
    order: null,
    deliveryRequest: null,
    'barcodes::text': null,
    place: null,
    productOffer: null,
    'productOffer==i': null,
    'order==at':null,
    count: {
      from: null,
      to: null
    },
    scannedCount: {
      from: null,
      to: null
    },
    queue: null,
    'state==i': [],
    'state==w': [],
    'warehouse==i': null,
    item: null,
    account: null,
    payer: null,
    payee: null,
    rateModifier: [],
    document: null,
  }
}

export default {
  name: 'FiltersCollapse',
  emits: ['submit', 'close'],
  props: {
    options: {
      type: Object,
      default () {
        return {
          defaultState: {},
          defaultFilter: [],
          values: {
            states: [],
            types: [],
            offerTypes: []
          },
          fields: [],
          services: {},
          query: {},
          style: {
            flat: false,
            onHalf: false,
            noGroups: false
          },
          schema: []
        }
      }
    },
    isOpen: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: createModel(),
      states: [],
      isLoaded: false,
      values: {
        states: [],
        'states==i': [],
        types: [],
        domains: AppOptions.servers.reduce((acc, { domain }) => {
          const id = domain || 'default'

          if (!acc.find(({ code }) => code === id)) {
            acc.push({ code: id, name: id })
          }

          return acc
        }, []),
        paymentStates: [
          {
            id: 'missing',
            name: this.$t('Missing')
          },
          {
            id: 'payment_waiting',
            name: this.$t('Awaiting payment')
          },
          {
            id: 'received',
            name: this.$t('Received')
          },
          {
            id: 'partly_payed',
            name: this.$t('Partly paid')
          },
          {
            id: 'paid',
            name: this.$t('Paid')
          },
          {
            id: 'not_paid',
            name: this.$t('Not paid')
          },
          {
            id: 'delivery_charged',
            name: this.$t('Shipment paid')
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    styleClasses () {
      return this.options.style && this.options.style.flat
        ? ''
        : 'shadow q-pa-sm rounded q-ma-xs'
    },
    schema () {
      const from = ' ' + this.$t('From')
      const to = ' ' + this.$t('To')

      const primaryGroup = {
        label: this.$t('Primary Information'),
        styleClasses: 'row',
        fields: [
          {
            type: 'input',
            inputType: 'text',
            value: this.model.id,
            field: 'id',
            icon: 'search',
            label: this.$t('ID'),
            hasResetBtn: true,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.id = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.barcode,
            field: 'barcode',
            hasResetBtn: true,
            label: this.$t('Barcode'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.barcode = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.document,
            field: 'documentId',
            icon: 'search',
            label: this.$t('Purchase order ID'),
            hasResetBtn: true,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.document = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.locationAddress,
            hasResetBtn: true,
            field: 'locationAddress',
            label: this.$t('Location address (room - row - rack - shelf)'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.locationAddress = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.extId,
            hasResetBtn: true,
            field: 'extId',
            label: this.$t('Ext. Id'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.extId = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.clientExtId,
            hasResetBtn: true,
            field: 'clientExtId',
            label: this.$t('Client Ext Id'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.clientExtId = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.order,
            label: this.$t('Order Id'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            hasResetBtn: true,
            field: 'orderId',
            onChange: (value) => {
              this.model.order = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.clientId,
            hasResetBtn: true,
            field: 'clientId',
            label: this.$t('Client Id'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.clientId = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.deliveryRequest,
            hasResetBtn: true,
            field: 'deliveryRequest',
            label: this.$t('Shipment'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.deliveryRequest = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.trackingNumber,
            hasResetBtn: true,
            field: 'trackingNumber',
            label: this.$t('Tracking number'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.trackingNumber = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.shippedByDocument,
            hasResetBtn: true,
            field: 'shippedByDocument',
            label: this.$t('Shipped by document'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.shippedByDocument = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.createdByDocument,
            hasResetBtn: true,
            field: 'createdByDocument',
            label: this.$t('Created by document'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.createdByDocument = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.createdByDocument,
            hasResetBtn: true,
            field: 'createdByDocument',
            label: this.$t('Created by document'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.createdByDocument = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model['created_by_document_id'],
            hasResetBtn: true,
            field: 'createdByDocumentId',
            label: this.$t('Created by document'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.createdByDocument = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.acceptanceItem,
            hasResetBtn: true,
            field: 'acceptanceItem',
            label: this.$t('Inbound item'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.acceptanceItem = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.article,
            hasResetBtn: true,
            field: 'article',
            label: this.$t('Article'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.article = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.sku,
            hasResetBtn: true,
            field: 'sku',
            label: this.$t('Sku'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.sku = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.batch,
            hasResetBtn: true,
            field: 'batch',
            label: this.$t('Batch'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.batch = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.placeAlias,
            hasResetBtn: true,
            field: 'placeAlias',
            label: this.$t('Location Name'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.placeAlias = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.name,
            hasResetBtn: true,
            field: 'name',
            label: this.$t('Name'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.name = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.username,
            hasResetBtn: true,
            field: 'username',
            label: this.$t('Username'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.username = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model.email,
            hasResetBtn: true,
            field: 'email',
            label: this.$t('Email'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.email = value
            }
          },
          {
            type: 'tag',
            label: this.$t('Type'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.type,
            field: 'type',
            options: this.values.types,
            customListItem: (val) => {
              return val.code
            },
            onRemove: (val) => {
              this.model.type = this.model.type.filter(x => x !== val)
            },
            onAdd: (value) => {
              if (Array.isArray(value)) {
                return
              }

              let isExist = false

              this.model.type = this.model.type.filter(val => {
                if (val === value.code) {
                  isExist = true
                  return false
                }

                return true
              })

              if (isExist) {
                return
              }

              this.model.type = [...this.model.type, value.code]
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model['barcodes::text'],
            field: 'barcodes::text',
            hasResetBtn: true,
            label: this.$t('Barcode'),
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model['barcodes::text'] = value
            }
          }
        ]
      }

      const recipientGroup = {
        label: this.$t('Recipient'),
        styleClasses: 'row',
        fields: [
          {
            type: 'input',
            inputType: 'text',
            value: this.model.recipient,
            field: 'recipient',
            hasResetBtn: true,
            label: this.$t('Recipient'),
            wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
            onChange: (value) => {
              this.model.recipient = value
            }
          },
          {
            type: 'input',
            inputType: 'tel',
            value: this.model.recipientPhone,
            field: 'recipientPhone',
            hasResetBtn: true,
            label: this.$t('Recipient phone'),
            wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
            onChange: (value) => {
              this.model.recipientPhone = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Profile'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            hasResetBtn: true,
            value: this.model.profile,
            field: 'profile',
            customLabel (row) {
              if (row && typeof row === 'object') {
                return `${row.name} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.profile) {
                query.filter = [...this.options.query.profile, ...query.filter]
              }

              return this.$service.clientProfile.getAll(query)
            },
            onChange: (value) => {
              this.model.profile = value
            }
          },
          {
            type: 'tel',
            value: this.model.phone,
            hasResetBtn: true,
            field: 'phone',
            label: this.$t('Phone'),
            wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
            onChange: (value) => {
              this.model.phone = (value || '').replaceAll(' ', '')
            }
          }
        ]
      }

      const productsGroup = {
        label: this.$t('Products'),
        styleClasses: 'row',
        fields: [
          {
            type: 'tag',
            label: this.$t('Status'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model['state==i'],
            field: 'state==i',
            options: this.values['states==i'],
            customListItem: (row) => {
              if (row && typeof row === 'object') {
                return row.name || row.title
              }

              return row
            },
            onRemove: (val) => {
              this.model['state==i'] = this.model['state==i'].filter(x => x !== val)
            },
            onAdd: (value) => {
              if (Array.isArray(value)) {
                return
              }

              let isExist = false

              this.model['state==i'] = this.model['state==i'].filter(val => {
                if (val === value.code) {
                  isExist = true
                  return false
                }

                return true
              })

              if (isExist) {
                return
              }

              this.model['state==i'] = [...this.model['state==i'], value.code]
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.items.from,
            label: this.$t('Quantity') + from,
            hasResetBtn: true,
            field: 'items.from',
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.items.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.items.to,
            hasResetBtn: true,
            field: 'items.to',
            label: this.$t('Quantity') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.items.to = value
            }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityExpected.from,
              label: this.$t('Quantity New') + from,
              hasResetBtn: true,
              field: 'quantityExpected.from',
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityExpected.from = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityExpected.to,
              hasResetBtn: true,
              field: 'quantityExpected.to',
              label: this.$t('Quantity New') + to,
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityExpected.to = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityReceived.from,
              label: this.$t('Quantity Received') + from,
              hasResetBtn: true,
              field: 'quantityReceived.from',
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityReceived.from = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityReceived.to,
              hasResetBtn: true,
              field: 'quantityReceived.to',
              label: this.$t('Quantity Received') + to,
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityReceived.to = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityAllocated.from,
              label: this.$t('Quantity On hand') + from,
              hasResetBtn: true,
              field: 'quantityAllocated.from',
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityAllocated.from = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityAllocated.to,
              hasResetBtn: true,
              field: 'quantityAllocated.to',
              label: this.$t('Quantity On hand') + to,
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityAllocated.to = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityShipped.from,
              label: this.$t('Quantity Shipped') + from,
              hasResetBtn: true,
              field: 'quantityShipped.from',
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityShipped.from = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.quantityShipped.to,
              hasResetBtn: true,
              field: 'quantityShipped.to',
              label: this.$t('Quantity Shipped') + to,
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.quantityShipped.to = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.orderQuantityFBA.from,
              label: this.$t('FBA Order Quantity') + from,
              hasResetBtn: true,
              field: 'orderQuantityFBA.from',
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.orderQuantityFBA.from = value
              }
          },
          {
              type: 'input',
              inputType: 'number',
              value: this.model.orderQuantityFBA.to,
              hasResetBtn: true,
              field: 'orderQuantityFBA.to',
              label: this.$t('FBA Order Quantity') + to,
              wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
              onChange: value => {
                  this.model.orderQuantityFBA.to = value
              }
          },
            {
                type: 'input',
                inputType: 'number',
                value: this.model.orderQuantityFBM.from,
                label: this.$t('FBM Order Quantity') + from,
                hasResetBtn: true,
                field: 'orderQuantityFBM.from',
                wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
                onChange: value => {
                    this.model.orderQuantityFBM.from = value
                }
            },
            {
                type: 'input',
                inputType: 'number',
                value: this.model.orderQuantityFBM.to,
                hasResetBtn: true,
                field: 'orderQuantityFBM.to',
                label: this.$t('FBM Order Quantity') + to,
                wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
                onChange: value => {
                    this.model.orderQuantityFBM.to = value
                }
            },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.weight.from,
            label: this.$t('Weight') + from,
            hasResetBtn: true,
            field: 'weight.from',
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.weight.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.weight.to,
            label: this.$t('Weight') + to,
            hasResetBtn: true,
            field: 'weight.to',
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.weight.to = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.price.from,
            hasResetBtn: true,
            field: 'price.from',
            label: this.$t('Price') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.price.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.price.to,
            hasResetBtn: true,
            field: 'price.to',
            label: this.$t('Price') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.price.to = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.retailPrice.from,
            hasResetBtn: true,
            field: 'retailPrice.from',
            label: this.$t('Retail Price') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.retailPrice.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.retailPrice.to,
            hasResetBtn: true,
            field: 'retailPrice.to',
            label: this.$t('Retail Price') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: value => {
              this.model.retailPrice.to = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            field: 'estimatedCost.from',
            hasResetBtn: true,
            value: this.model.estimatedCost.from,
            label: this.$t('Estimated Price') + from,
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.estimatedCost.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.estimatedCost.to,
            label: this.$t('Estimated Price') + to,
            hasResetBtn: true,
            field: 'estimatedCost.to',
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.estimatedCost.to = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.payment.from,
            hasResetBtn: true,
            field: 'payment.from',
            label: this.$t('Payment') + from,
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.payment.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.payment.to,
            hasResetBtn: true,
            field: 'payment.to',
            label: this.$t('Payment') + to,
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.payment.to = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.balance.from,
            hasResetBtn: true,
            field: 'balance.from',
            label: this.$t('Balance') + from,
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.balance.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.balance.to,
            hasResetBtn: true,
            field: 'balance.to',
            label: this.$t('Balance') + to,
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.balance.to = value
            }
          }
        ]
      }

      const dateGroup = {
        label: this.$t('Date'),
        styleClasses: 'row',
        fields: [
          {
            type: 'date',
            value: this.model.shipmentDate.from,
            field: 'shipmentDate.from',
            hasResetBtn: true,
            label: this.$t('Ship Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.shipmentDate.from = value
            }
          },
          {
            type: 'date',
            value: this.model.shipmentDate.to,
            field: 'shipmentDate.to',
            hasResetBtn: true,
            label: this.$t('Ship Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.shipmentDate.to = value
            }
          },
          {
            type: 'date',
            field: 'deliveryDate.from',
            value: this.model.deliveryDate.from,
            hasResetBtn: true,
            label: this.$t('Delivery Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.deliveryDate.from = value
            }
          },
          {
            type: 'date',
            value: this.model.deliveryDate.to,
            hasResetBtn: true,
            field: 'deliveryDate.to',
            label: this.$t('Delivery Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.deliveryDate.to = value
            }
          },
          {
            type: 'input',
            inputType: 'time',
            value: this.model.deliveryTime,
            hasResetBtn: true,
            field: 'deliveryTime',
            label: this.$t('Delivery Time'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.deliveryDate.to = value
            }
          },
          {
            type: 'date',
            value: this.model.pickUpDate.from,
            hasResetBtn: true,
            field: 'pickUpDate.from',
            label: this.$t('Pick Up Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.pickUpDate.from = value
            }
          },
          {
            type: 'date',
            value: this.model.pickUpDate.to,
            hasResetBtn: true,
            field: 'pickUpDate.to',
            label: this.$t('Pick Up Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.pickUpDate.to = value
            }
          },
          {
            type: 'date',
            value: this.model.sendDate.from,
            hasResetBtn: true,
            field: 'sendDate.from',
            label: this.$t('Ship Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.sendDate.from = value
            }
          },
          {
            type: 'date',
            value: this.model.sendDate.to,
            hasResetBtn: true,
            field: 'sendDate.to',
            label: this.$t('Ship Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.sendDate.to = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'transactionDate.from',
            value: this.model.transactionDate.from,
            label: this.$t('Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.transactionDate.from = value
            }
          },
          {
            type: 'date',
            value: this.model.transactionDate.to,
            hasResetBtn: true,
            field: 'transactionDate.to',
            label: this.$t('Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.transactionDate.to = value
            }
          },
          {
            type: 'date',
            value: this.model.expires,
            hasResetBtn:true,
            field: 'expires',
            label: this.$t('Expire Date'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              const result = value.replaceAll('/', '-')

              this.model.expires = result
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'updated.from',
            value: this.model.updated.from,
            label: this.$t('Update Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.updated.from = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'updated.to',
            value: this.model.updated.to,
            label: this.$t('Update Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.updated.to = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'dates.from',
            value: this.model.date.from,
            label: this.$t('Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.date.from = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'dates.to',
            value: this.model.date.to,
            label: this.$t('Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.date.to = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'created.from',
            value: this.model.created.from,
            label: this.$t('Created Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.created.from = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'created.to',
            value: this.model.created.to,
            label: this.$t('Created Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.created.to = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'extDate.from',
            value: this.model.extDate.from,
            label: this.$t('External Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.extDate.from = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'extDate.to',
            value: this.model.extDate.to,
            label: this.$t('External Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.extDate.to = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'date.from',
            value: this.model.updated.from,
            label: this.$t('Date') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.updated.from = value
            }
          },
          {
            type: 'date',
            hasResetBtn: true,
            field: 'date.to',
            value: this.model.updated.to,
            label: this.$t('Date') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.updated.to = value
            }
          },
          {
            type: 'input',
            hasResetBtn: true,
            inputType: 'number',
            field: 'count.from',
            value: this.model.count.from,
            label: this.$t('Count') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.count.from = value
            }
          },
          {
            type: 'input',
            hasResetBtn: true,
            inputType: 'number',
            field: 'count.to',
            value: this.model.count.to,
            label: this.$t('Count') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.count.to = value
            }
          },
          {
            type: 'input',
            hasResetBtn: true,
            inputType: 'number',
            field: 'scannedCount.from',
            value: this.model.scannedCount.from,
            label: this.$t('Scanned Count') + from,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.scannedCount.from = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            hasResetBtn: true,
            field: 'scannedCount.to',
            value: this.model.scannedCount.to,
            label: this.$t('Scanned Count') + to,
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.scannedCount.to = value
            }
          }
        ]
      }

      const otherGroup = {
        label: this.$t('Others'),
        styleClasses: 'row',
        fields: [
          {
            type: 'input',
            inputType: 'text',
            value: this.model.entityClass,
            label: this.$t('Entity class'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            hasResetBtn: true,
            field: 'entityClass',
            onChange: (value) => {
              this.model.entityClass = value
            }
          },
          {
            type: 'input',
            value: this.model.reserve,
            field: 'reserve',
            hasResetBtn: true,
            label: this.$t('Reserve'),
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.reserve = value
            }
          },
          {
            type: 'input',
            value: this.model.item,
            field: 'item',
            hasResetBtn: true,
            label: this.$t('Item'),
            wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
            onChange: value => {
              this.model.item = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Country'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.country,
            hasResetBtn: true,
            field: 'country',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.country) {
                query.filter = [...this.options.query.country, ...query.filter]
              }

              return this.$service.country.getAll(query)
            },
            onChange: (value) => {
              this.model.country = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Owner'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.owner,
            hasResetBtn: true,
            field: 'owner',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.owner) {
                query.filter = [...this.options.query.owner, ...query.filter]
              }

              return this.$service.user.getAll(query)
            },
            onChange: (value) => {
              this.model.owner = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Worker name'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.executive,
            hasResetBtn: true,
            field: 'executive',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.executive) {
                query.filter = [...this.options.query.executive, ...query.filter]
              }

              return this.$service.user.getAll(query)
            },
            onChange: (value) => {
              this.model.executive = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Queue'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.queue,
            hasResetBtn: true,
            field: 'queue',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.queue) {
                query.filter = [...this.options.query.queue, ...query.filter]
              }

              const service = (this.options.services && this.options.services.queue) || this.$service.pickingQueue

              return service.getAll(query)
            },
            onChange: (value) => {
              this.model.queue = value
            }
          },
          {
            type: 'tag',
            label: this.$t('Shipping carrier'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            field: 'deliveryService',
            value: this.model.deliveryService,
            customTag: (item) => {
              return item && typeof item === 'object'
                ? (item.name || item.title || this.$t('No name')) + ` (${item.id})`
                : item
            },
            customListItem: (item) => {
              return item && typeof item === 'object'
                ? (item.name || item.title || this.$t('No name')) + ` (${item.id})`
                : item
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                // search,
                filter: []
              }

              query.filter.push({
                type: 'ilike',
                field: 'name',
                value: search
              })

              if (this.options.query && this.options.query.deliveryService) {
                query.filter = [...this.options.query.deliveryService, ...query.filter]
              }

              return this.$service.deliveryService.getAll(query)
            },
            onRemove: (val) => {
              this.model.deliveryService = this.model.deliveryService.filter(x => x.id !== val.id)
            },
            onAdd: (value) => {
              if (Array.isArray(value)) {
                return
              }

              let hasValue = false

              const values = this.model.deliveryService.filter(val => {
                if (val.id === value.id) {
                  hasValue = true
                  return false
                }

                return true
              })

              if (hasValue) {
                this.model.deliveryService = values
                return
              }

              this.model.deliveryService.push(value)
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Shipping rate'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.rate,
            hasResetBtn: true,
            field: 'rate',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.rate) {
                query.filter = [...this.options.query.rate, ...query.filter]
              }

              return this.$service.deliveryServiceRate.getAll(query)
            },
            onChange: (value) => {
              this.model.rate = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Pick up point'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.servicePoint,
            hasResetBtn: true,
            field: 'servicePoint',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.servicePoint) {
                query.filter = [...this.options.query.servicePoint, ...query.filter]
              }

              return this.$service.servicePoint.getAll(query)
            },
            onChange: (value) => {
              this.model.servicePoint = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Integration name'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.integration,
            hasResetBtn: true,
            field: 'integration',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.integration) {
                query.filter = [...this.options.query.integration, ...query.filter]
              }

              return this.$service.deliveryServiceIntegration.getAll(query)
            },
            onChange: (value) => {
              this.model.integration = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Report type'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.reportType,
            hasResetBtn: true,
            field: 'reportType',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.reportType) {
                query.filter = [...this.options.query.reportType, ...query.filter]
              }

              return this.$service.reportType.getAll(query)
            },
            onChange: (value) => {
              this.model.reportType = value
            }
          },
          {
            type: 'select',
            label: this.$t('Payment status'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.paymentState,
            options: this.values.paymentStates,
            hasResetBtn: true,
            field: 'paymentState',
            customLabel: (row) => {
              return row && typeof row === 'object'
                ? row.name
                : row
            },
            onChange: (paymentState) => {
              this.model = { ...this.model, paymentState }
            }
          },
          {
            type: 'tag',
            label: this.$t('Status'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.state,
            field: 'state',
            options: this.values.states,
            customListItem: (row) => {
              if (row && typeof row === 'object') {
                return row.name
              }

              return row
            },
            onRemove: (val) => {
              this.model.state = this.model.state.filter(x => x !== val)
            },
            onAdd: (value) => {
              if (Array.isArray(value)) {
                return
              }

              let isExist = false

              this.model.state = this.model.state.filter(val => {
                if (val === value.code) {
                  isExist = true
                  return false
                }

                return true
              })

              if (isExist) {
                return
              }

              this.model.state = [...this.model.state, value.code]
            }
          },
            {
                type: 'select',
                label: this.$t('Order Type'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                value: this.model.offerType,
                options: this.values.offerTypes,
                hasResetBtn: true,
                field: 'offerType',
                customLabel: (row) => {
                    return row && typeof row === 'object'
                        ? row.name
                        : row
                },
                onChange: (offerType) => {
                    this.model = { ...this.model, offerType }
                }
            },
          // {
          //   type: 'multiselect',
          //   label: this.$t('Charge name'),
          //   wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
          //   value: this.model.rateModifier,
          //   hasResetBtn: true,
          //   field: 'rateModifier',
          //   customLabel: (row) => {
          //     if (row && typeof row === 'object') {
          //       return `${row.name || this.$t('No name')} (${row.id})`
          //     }
          //
          //     return row
          //   },
          //   onScroll: (search, page) => {
          //     const query = {
          //       per_page: 25,
          //       page,
          //       search,
          //       filter: []
          //     }
          //
          //     if (this.options.query && this.options.query.rateModifier) {
          //       query.filter = [...this.options.query.rateModifier, ...query.filter]
          //     }
          //
          //     return this.$service.rateModifier.getAll(query)
          //   },
          //   onChange: (value) => {
          //     this.model.rateModifier = value
          //   }
          // },
            {
              type: 'tag',
                label: this.$t('Charge name'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                value: this.model.rateModifier,
                field: 'rateModifier',
                options: [],
                onScroll: (search, page) => {
                    const query = {
                        per_page: 25,
                        page,
                        search,
                        filter: []
                    }

                    if (this.options.query && this.options.query.rateModifier) {
                        query.filter = [...this.options.query.rateModifier, ...query.filter]
                    }

                    return this.$service.rateModifier.getAll(query)
                },
                customTag: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                customListItem: (row) => {
                    if (row && typeof row === 'object') {
                        return `${row.name || this.$t('No name')} (${row.id})`
                    }

                    return row
                },
                onRemove: (val) => {
                    this.model.rateModifier = this.model.rateModifier.filter(x => x.id !== val.id)
                },
                onAdd: (value) => {
                    if (Array.isArray(value)) {
                        return
                    }

                    let isExist = false

                    this.model.rateModifier = this.model.rateModifier.filter(val => {
                        if (val.id === value.id) {
                            isExist = true
                            return false
                        }
                        return true
                    })

                    if (isExist) {
                        return
                    }

                    this.model.rateModifier = [...this.model.rateModifier, value]
                }

            },
          {
            type: 'multiselect',
            label: this.$t('Account'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.account,
            hasResetBtn: true,
            field: 'account',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.account) {
                query.filter = [...this.options.query.account, ...query.filter]
              }

              return this.$service.accounts.getAll(query)
            },
            onChange: (value) => {
              this.model.account = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Payee'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.payee,
            hasResetBtn: true,
            field: 'payee',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.payee) {
                query.filter = [...this.options.query.payee, ...query.filter]
              }

              return this.$service.legalEntity.getAll(query)
            },
            onChange: (value) => {
              this.model.payee = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Payer'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.payer,
            hasResetBtn: true,
            field: 'payer',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.payer) {
                query.filter = [...this.options.query.payer, ...query.filter]
              }

              return this.$service.legalEntity.getAll(query)
            },
            onChange: (value) => {
              this.model.payer = value
            }
          },
          {
            type: 'tag',
            label: this.$t('Domain'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.domain,
            options: this.values.domains,
            field: 'domains',
            customListItem: (row) => {
              if (row && typeof row === 'object') {
                return row.name
              }

              return row
            },
            onRemove: (val) => {
              this.model.domain = this.model.domain.filter(x => x !== val)
            },
            onAdd: (value) => {
              if (Array.isArray(value)) {
                return
              }

              let isExist = false

              this.model.domain = this.model.domain.filter(val => {
                if (val === value.code) {
                  isExist = true
                  return false
                }

                return true
              })

              if (isExist) {
                return
              }

              this.model.domain = [...this.model.domain, value.code]
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Sender name'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.sender,
            hasResetBtn: true,
            field: 'sender',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.sender) {
                query.filter = [...this.options.query.sender, ...query.filter]
              }

              return this.$service.sender.getAll(query)
            },
            onChange: (value) => {
              this.model.sender = value
            }
          },
          {
            type: 'tag',
            label: this.$t('Stores'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            field: 'shop',
            value: this.model.shop,
            customTag: (item) => {
              return item && typeof item === 'object'
                ? (item.name || item.title || this.$t('No name')) + ` (${item.id})`
                : item
            },
            customListItem: (item) => {
              return item && typeof item === 'object'
                ? (item.name || item.title || this.$t('No name')) + ` (${item.id})`
                : item
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.shop) {
                query.filter = [...this.options.query.shop, ...query.filter]
              }

              return this.$service.shop.getAll(query)
            },
            onRemove: (val) => {
              this.model.shop = this.model.shop.filter(x => x.id !== val.id)
            },
            onAdd: (value) => {
              if (Array.isArray(value)) {
                return
              }

              let hasValue = false

              const values = this.model.shop.filter(val => {
                if (val.id === value.id) {
                  hasValue = true
                  return false
                }

                return true
              })

              if (hasValue) {
                this.model.shop = values
                return
              }

              this.model.shop.push(value)
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Storage Place'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.place,
            hasResetBtn: true,
            field: 'place',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.placeAlias || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.place) {
                query.filter = [...this.options.query.place, ...query.filter]
              }

              return this.$service.storagePlace.getAll(query)
            },
            onChange: (value) => {
              this.model.place = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Product'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.productOffer,
            hasResetBtn: true,
            field: 'productOffer',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.productOffer) {
                query.filter = [...this.options.query.productOffer, ...query.filter]
              }

              return this.$service.offer.getAll(query)
            },
            onChange: (value) => {
              this.model.productOffer = value
            },
            buttons: [
              {
                id: 'e1',
                color: this.model.isNull.includes('productOffer')
                    ? 'positive'
                    : 'dark',
                label: this.$t('Empty'),
                size: 'lg',
                styleClasses: 'q-ml-sm',
                onClick: () => {
                  let hasValue = false

                  this.model.isNull = this.model.isNull.filter(val => {
                    if (val === 'productOffer') {
                      hasValue = true
                      return false
                    }

                    return true
                  })

                  if (hasValue) {
                    return
                  }

                  this.model.isNull.push('productOffer')
                }
              }
            ]
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model['productOffer==i'],
            field: 'productOffer==i',
            hasResetBtn: true,
            label: this.$t('Product Offer'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model['productOffer==i'] = value
            }
          },
          {
            type: 'input',
            inputType: 'text',
            value: this.model['order==at'],
            field: 'order==at',
            hasResetBtn: true,
            label: this.$t('Order'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model['order==at'] = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Order'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.order,
            hasResetBtn: true,
            field: 'order',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                const date = typeof row.created === 'object'
                  ? row.created.date
                  : row.created

                return `${row.id}: ${row._embedded.profile.name} - ${row.state} - ${this.$moment(date).format(this.appOptions.formats.date)}`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.order) {
                query.filter = [...this.options.query.order, ...query.filter]
              }

              return this.$service.order.getAll(query)
            },
            onChange: (value) => {
              this.model.order = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Ecommerce platform'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.source,
            hasResetBtn: true,
            field: 'source',
            disabled: this.model.isNull.includes('source'),
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.source) {
                query.filter = [...this.options.query.source, ...query.filter]
              }

              return this.$service.iSource.getAll(query)
            },
            onChange: (value) => {
              this.model.source = value
            },
            buttons: [
              {
                id: 'e1',
                color: this.model.isNull.includes('source')
                  ? 'positive'
                  : 'dark',
                label: this.$t('Empty'),
                size: 'lg',
                styleClasses: 'q-ml-sm',
                onClick: () => {
                  let hasValue = false

                  this.model.isNull = this.model.isNull.filter(val => {
                    if (val === 'source') {
                      hasValue = true
                      return false
                    }

                    return true
                  })

                  if (hasValue) {
                    return
                  }

                  this.model.isNull.push('source')
                }
              }
            ]
          },
          {
            type: 'multiselect',
            label: this.$t('Channel'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.channel,
            hasResetBtn: true,
            field: 'channel',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.channel) {
                query.filter = [...this.options.query.channel, ...query.filter]
              }

              return this.$service.notificationChannel.getAll(query)
            },
            onChange: (value) => {
              this.model.channel = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Sequence'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.sequence,
            hasResetBtn: true,
            field: 'sequence',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.sequence) {
                query.filter = [...this.options.query.sequence, ...query.filter]
              }

              return this.$service.pickingSequence.getAll(query)
            },
            onChange: (value) => {
              this.model.sequence = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Task'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model.task,
            hasResetBtn: true,
            field: 'task',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.task) {
                query.filter = [...this.options.query.task, ...query.filter]
              }

              return this.$service.notificationTask.getAll(query)
            },
            onChange: (value) => {
              this.model.task = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Warehouse'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            hasResetBtn: true,
            value: this.model.warehouse,
            field: 'warehouse',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.warehouse) {
                query.filter = [...this.options.query.warehouse, ...query.filter]
              }

              return this.$service.warehouse.getAll(query)
            },
            onChange: (value) => {
              this.model.warehouse = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Warehouse'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model['warehouse==i'],
            hasResetBtn: true,
            field: 'warehouse==i',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.name || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query['warehouse==i']) {
                query.filter = [...this.options.query['warehouse==i'], ...query.filter]
              }

              return this.$service.warehouse.getAll(query)
            },
            onChange: (value) => {
              this.model['warehouse==i'] = value
            }
          },
          {
            type: 'multiselect',
            label: this.$t('Parent Location'),
            wrapperStyleClasses: 'col-12 col-md-4 q-px-xs',
            hasResetBtn: true,
            value: this.model.parent,
            field: 'parent',
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                return `${row.placeAlias || this.$t('No name')} (${row.id})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: []
              }

              if (this.options.query && this.options.query.parent) {
                query.filter = [...this.options.query.parent, ...query.filter]
              }

              return this.$service.storagePlace.getAll(query)
            },
            onChange: (value) => {
              this.model.parent = value
            }
          },
          {
            type: 'tag',
            label: this.$t('Warehouse status'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            value: this.model['state==w'],
            field: 'state==w',
            options: this.values['state==w'],
            customListItem: (row) => {
              if (row && typeof row === 'object') {
                return row.name || row.title
              }

              return row
            },
            onRemove: (val) => {
              this.model['state==w'] = this.model['state==w'].filter(x => x !== val)
            },
            onAdd: (value) => {
              if (Array.isArray(value)) {
                return
              }

              let isExist = false

              this.model['state==w'] = this.model['state==w'].filter(val => {
                if (val === value.code) {
                  isExist = true
                  return false
                }

                return true
              })

              if (isExist) {
                return
              }

              this.model['state==w'] = [...this.model['state==w'], value.code]
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.room,
            hasResetBtn: true,
            field: 'room',
            label: this.$t('Room'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.room = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.row,
            hasResetBtn: true,
            field: 'row',
            label: this.$t('Row'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.row = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.rack,
            hasResetBtn: true,
            field: 'rack',
            label: this.$t('Rack'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.rack = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.shelf,
            hasResetBtn: true,
            field: 'shelf',
            label: this.$t('Shelf'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            onChange: (value) => {
              this.model.shelf = value
            }
          },
          {
            type: 'input',
            inputType: 'number',
            value: this.model.volume,
            hasResetBtn: true,
            field: 'volume',
            label: this.$t('Volume'),
            wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            onChange: (value) => {
              this.model.volume = value
            }
          },
          ...(this.options.schema || [])
        ]
      }

      let type = null

      if (this.model.isNull.includes('rate')) {
        type = 'Non-assigned'
      } else if (this.model.isNotNull.includes('rate')) {
        type = 'Assigned'
      }

      const buttons = {
        label: this.$t('Buttons'),
        styleClasses: 'row',
        fields: [
          {
            type: 'select',
            label: this.$t('Mode'),
            wrapperStyleClasses: 'col-12 col-md-4 col-lg-2 q-pa-xs',
            value: type,
            options: [
              { id: 'assigned', name: 'Assigned' },
              { id: 'non-assigned', name: 'Non-assigned' }
            ],
            field: 'btn-rate',
            hasResetBtn: true,
            customLabel: (row) => {
              return this.$t(
                row && typeof row === 'object'
                  ? row.name
                  : row || ''
                )
            },
            onChange: (option) => {
              const clean = () => {
                this.model.isNull = this.model.isNull.filter(x => x !== 'rate')
                this.model.isNotNull = this.model.isNotNull.filter(x => x !== 'rate')
              }

              const actions = {
                assigned: () => {
                  clean()
                  this.model.isNotNull.push('rate')
                },
                'non-assigned': () => {
                  clean()
                  this.model.isNull.push('rate')
                }
              }

              if (typeof actions[option && option.id] === 'function') {
                actions[option.id]()
              } else {
                clean()
              }
            }
          }
        ]
      }

      const groups = [
        primaryGroup,
        recipientGroup,
        productsGroup,
        dateGroup,
        otherGroup,
        buttons
      ]

      if (this.options.style && this.options.style.onHalf) {
        groups.forEach(group => {
          group.fields.forEach(field => {
            field.wrapperStyleClasses = 'col-12 col-md-6 q-pa-xs'
          })
        })
      } else if (this.options.style && this.options.style.allOnThird) {
        groups.forEach(group => {
          group.fields.forEach(field => {
            field.wrapperStyleClasses = 'col-12 col-md-4 q-pa-xs'
          })
        })
      }

      return { groups }
    }
  },
  watch: {
    model: {
      handler (value, oldValue) {
        if (this.isLoaded) {
          this.$emit('update', { value, oldValue })
        }

        if (this.isLoaded && this.options.changeEvent) {
          this.$emit('filter-change', this.getFilter())
        }
      },
      deep: true
    },
    isOpen (newValue) {
      if (newValue && !this.isLoaded) {
        this.onLoad()
      }
    }
  },
  mounted () {
    if (this.isOpen) {
      this.onLoad()
    }
  },
  methods: {
    onLoad () {
      this.isLoading = true
      this.setValues(this.options.values || {})

      if (this.options.defaultState || !this.options.defaultFilter) {
        this.setState(this.options.defaultState || {})
        this.isLoaded = true
        this.isLoading = false
        return
      }

      this.transformFilter(this.options.defaultFilter)
        .then(state => {
          this.setState(state)
          this.isLoaded = true
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    isMatrix (value) {
      if (value[0] && value[0].buttons) {
        return true
      }

      return false
    },
    transformMatrix (value) {
      return value.reduce((acc, group) => {
        return [...acc, ...group.buttons.map(({ id, title }) => ({ name: title, code: id }))]
      }, [])
    },
    createValues (value) {
      return value.map(({ id, title }) => ({ name: title, code: id }))
    },
    setValues (values) {
      const params = Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = this.isMatrix(value)
          ? this.transformMatrix(value)
          : this.createValues(value)
        return acc
      }, {})

      this.values = {
        ...this.values,
        ...params
      }

      this.values.states = params.states || params.statuses || []
    },
    setState (state) {
      this.model = {
        ...createModel(),
        ...state
      }
    },
    transformFilter (filter) {
      const model = {
        isNull: []
      }

      const services = {
        deliveryService: this.$service.deliveryService,
        warehouse: this.$service.warehouse,
        rate: this.$service.deliveryServiceRate,
        servicePoint: this.$service.servicePoint,
        integration: this.$service.deliveryServiceIntegration,
        sender: this.$service.sender,
        shop: this.$service.shop,
        profile: this.$service.clientProfile,
        channel: this.$service.notificationChannel,
        source: this.$service.iSource,
        task: this.$service.notificationTask,
        executive: this.$service.user,
        owner: this.$service.user,
        country: this.$service.country,
        handler: this.$service.reportType,
        root: this.$service.storagePlace,
        sequence: this.$service.pickingSequence,
        account: this.$service.accounts,
        payee: this.$service.legalEntity,
        payer: this.$service.legalEntity,
        rateModifier: this.$service.rateModifier
      }

      const onLoad = (filter) => {
        if (filter.type === 'isnull') {
          model.isNull.push(filter.field)
          return Promise.resolve(null)
        }

        const key = filter.alias
          ? `${filter.field}==${filter.alias}`
          : filter.field

        if (services[filter.field]) {
          const query = {
            page: 1,
            per_page: filter.values
              ? filter.values.length
              : 1,
            filter: [{ ...filter, field: 'id' }]
          }

          return services[filter.field].getAll(query)
            .then(({ items }) => {
              model[key] = Array.isArray(this.model[key])
                ? items
                : items[0]
              return model[key]
            })
        }

        const value = this.$utils.filter.filterToObject(filter)[filter.field]

        model[key] = Array.isArray(this.model[key]) && !Array.isArray(value)
          ? [value]
          : value

        return Promise.resolve(model[key])
      }

      return Promise.all(filter.map(onLoad))
        .then(() => {
          return model
        })
    },
    getFilter () {
      return Object.entries(this.model).reduce((acc, [key, value]) => {
        if (key === 'isNull') {
          return acc
        }


        if (key === 'isNotNull') {
          value.forEach(field => {
            acc.push({ type: 'isnotnull', field })
          })

          return acc
        }

        if (this.model.isNull.includes(key)) {
          acc.push({ type: 'isnull', field: key })
          return acc
        }

        if (key.includes('==')) {
          const [field, alias] = key.split('==')
          const filter = this.$utils.filter.create(field, value, alias)

          return [
            ...acc,
            ...filter
          ]
        }
        if (key === 'offerType' && value) {
          const filter = {
              type: 'jsonboperator',
              subfield: 'integrations-amazon-offer-type',
              field: 'eav',
              value: value.code,
              subtype: 'eq',
              native: true
          }

          return [
              ...acc,
              filter
          ]
        }

        if (key === 'orderQuantityFBA' && value){
            if(value.from) {
                const filter = {
                    type: 'jsonboperator',
                    subfield: 'integrations-amazon-offer-fba-quantity',
                    field: 'eav',
                    subtype: 'gte',
                    value: value.from,
                    native: true
                }

                acc = [
                    ...acc,
                    filter
                ]
            }
            if(value.to) {
              const filter = {
                  type: 'jsonboperator',
                  subfield: 'integrations-amazon-offer-fba-quantity',
                  field: 'eav',
                  subtype: 'lte',
                  value: value.to,
                  native: true
              }

              acc = [
                  ...acc,
                  filter
              ]

            }
            return acc
        }
          if (key === 'orderQuantityFBM' && value){
              if(value.from) {
                  const filter = {
                      type: 'jsonboperator',
                      subfield: 'integrations-amazon-offer-fbm-quantity',
                      field: 'eav',
                      subtype: 'gte',
                      value: value.from,
                      native: true
                  }

                  acc = [
                      ...acc,
                      filter
                  ]
              }
              if(value.to) {
                  const filter = {
                      type: 'jsonboperator',
                      subfield: 'integrations-amazon-offer-fbm-quantity',
                      field: 'eav',
                      subtype: 'lte',
                      value: value.to,
                      native: true
                  }

                  acc = [
                      ...acc,
                      filter
                  ]

              }
              return acc
          }

          const filter = this.$utils.filter.create(key, value)

        return [
          ...acc,
          ...filter
        ]
      }, [])
    },
    handleSubmit (e) {
      if (e && e.preventDefault) {
        e.preventDefault()
      }

      this.$emit('submit', this.getFilter())
    },
    handleReset () {
      this.model = createModel()
      this.$emit('submit', [])
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>
